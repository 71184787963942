/* Main
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#header-info {
  a:not(.collapsed) .rotate-icon {
    transform: rotate(180deg);
  }
}

.main {
	margin-bottom: 2rem;

	@include media-breakpoint-up(lg) {
		margin-bottom: 3rem;
	}

	@include media-breakpoint-up(xl) {
		margin-bottom: 6rem;
	}

	@include media-breakpoint-up(xxl) {
		margin-bottom: 8rem;
	}

	> .row {

		&:not(:last-child) {
			margin-bottom: 2rem;

			@include media-breakpoint-up(lg) {
				margin-bottom: 3rem;
			}

			@include media-breakpoint-up(xl) {
				margin-bottom: 6rem;
			}

			@include media-breakpoint-up(xxl) {
				margin-bottom: 8rem;
			}

		}

	}

}

/* Frontpage
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.image-text-row {

	@include media-breakpoint-up(xl) {
		margin-bottom: 5.63rem;
	}

	@include media-breakpoint-up(lg) {
		align-items: center;
	}

	.image-inner {
		@include media-breakpoint-down(md) {
			margin-bottom: 2rem;
		}
	}

	.text-inner {

		p {
			@include media-breakpoint-up(lg) {
				margin-bottom: 0;
			}
		}

	}

}

// Text-Image-Row
.text-image-row {

	@include media-breakpoint-up(lg) {
		align-items: center;
	}

	.text-inner {
		@include media-breakpoint-down(md) {
			margin-bottom: 2rem;
		}

		p {
			@include media-breakpoint-up(lg) {
				margin-bottom: 0;
			}
		}

	}

}

// Image-Image-Row
.image-image-row {
	margin-bottom: 0;

	.image-inner {
		@include media-breakpoint-down(md) {
			margin-bottom: 2rem;
		}
	}

	.image-2 {
		@include media-breakpoint-up(lg) {
			margin-top: 6rem;
		}

		@include media-breakpoint-up(xl) {
			margin-top: 7rem;
		}

		@include media-breakpoint-up(xxl) {
			margin-top: 11.25rem;
		}

	}

}

// Headline-Row
.headline-row {

	.headline {
		text-align: center;

		@include media-breakpoint-up(md) {
			max-width: 620px;
		}

		@include media-breakpoint-up(lg) {
			max-width: 720px;
		}

		@include media-breakpoint-up(xl) {
			max-width: 830px;
		}

		@include media-breakpoint-up(xxl) {
			max-width: 1200px;
		}

		h1 {
			font-size: 2.5rem;
			line-height: 1.1;
			margin-bottom: 0;

			@include media-breakpoint-up(md) {
				font-size: 3.75rem;
				line-height: 1;
			}

			@include media-breakpoint-up(lg) {
				font-size: 4.5rem;
			}

			@include media-breakpoint-up(xl) {
				font-size: 5.2rem;
			}

			@include media-breakpoint-up(xxl) {
				font-size: 7.2rem;
			}
		}

	}

}


/* Team
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.team-item {
	margin-bottom: 1rem;
}

.team-photo {
	margin-bottom: 2rem;

	@include media-breakpoint-up(lg) {
		margin-bottom: 4rem;
		max-width: 320px;
	}

}

/* Agenda
–––––––––––––––––––––––––––––––––––––––––––––––––– */
#agenda {

	.agenda-inner {

		@include media-breakpoint-up(md) {
			display: block;
			width: 100%;
			overflow-x: auto;
			-webkit-overflow-scrolling: touch;
			-ms-overflow-style: -ms-autohiding-scrollbar;
		}

		@include media-breakpoint-up(xxl) {
			overflow-x: visible;
			-webkit-overflow-scrolling: none;
			-ms-overflow-style: none;
		}

	}

}

.agenda-col {

	@include media-breakpoint-up(md) {
		border-left: 1px solid theme-color("primary");
	}

	@include media-breakpoint-up(xl) {
		padding-left: 5px;
		padding-right: 5px;
	}

	@include media-breakpoint-up(xxl) {
		padding-left: 15px;
		padding-right: 15px;
	}

	&:last-of-type {
		@include media-breakpoint-up(md) {
			border-right: 1px solid theme-color("primary");
		}
	}

	.weekday-header {

		@include media-breakpoint-down(sm) {
		    padding: 1rem 1.25rem;
		    margin-bottom: 0;
		    background-color: rgba(0,0,0,.03);
		    border-bottom: 1px solid rgba(0,0,0,.125);
		}

	    &:first-child {
	    	@include media-breakpoint-down(sm) {
	    		border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
	    	}
	    }

	}

	.agenda-weekday {
		margin-bottom: 0;
		font-size: 1.75rem;

		@include media-breakpoint-up(md) {
			margin-bottom: 1rem;
			text-align: center;
			font-size: 1.2rem;
		}

		@include media-breakpoint-up(lg) {
			margin-bottom: 1rem;
		}

		@include media-breakpoint-up(xl) {
			font-size: 1.2rem;
		}

		@include media-breakpoint-up(xxl) {
			font-size: 2rem;
		}

		a {
			display: block;
			position: relative;

			@include media-breakpoint-up(md) {
				color: $body-color;

				&:hover, &:focus {
					cursor: default;
				}

			}

			.chevron {
				position: absolute;
				right: 0;

				@include media-breakpoint-up(md) {
					display: none;
				}

			}

		}

	}

	.weekday-content {

		.weekday-content-inner {
			padding: 1.5rem 1rem;

			@include media-breakpoint-up(lg) {
				padding: 1rem 0rem;
			}

			@include media-breakpoint-up(xl) {
				padding: 1rem 0.5rem;
			}

		}

		@include media-breakpoint-up(md) {
			display: block;
		}

	}

	.agenda-entry {
		font-size: 1.25rem;
		position: relative;

		@include media-breakpoint-up(md) {
			font-size: 0.8rem;
		}

		@include media-breakpoint-up(xxl) {
			font-size: 1rem;
		}

		h5 {

			@include media-breakpoint-up(md) {
				font-size: 0.8rem;
			}

			@include media-breakpoint-up(xxl) {
				font-size: 1rem;
			}

		}

		&:not(:first-of-type) {
			margin-top: 1.5rem;
			padding-top: 1.5rem;

			@include media-breakpoint-up(lg) {
				margin-top: 2rem;
				padding-top: 2rem;
			}

			&:before {
				content: "";
				position: absolute;
				border-bottom: 1px solid rgba(0,0,0,.125);
				height: 1px;
				width: 100%;
				top: 0;

				@include media-breakpoint-up(md) {
					width: 10px;
					height: 10px;
					border-radius: 50%;
					transform: translateX(-50%);
					left: 50%;
					background: theme-color("primary");
					margin-top: -5px;
				}

			}

		}

		h5 {
			color: theme-color('primary');
			font-family: 'FolioBT-Light';
			margin-bottom: 0.25rem;
		}

		p {
			margin-bottom: 0;
		}

		.date-leader {
			color: theme-color('primary');
			font-size: 1rem;
			margin-top: 0.5rem;

			@include media-breakpoint-up(md) {
				font-size: 0.8rem;
			}

		}

	}

}


/* Events
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.event-entries {
	display: flex;
  	flex-direction: column;
  	flex-wrap: wrap;
}

.event-entries-inner {
	column-count: 2;
  	column-gap: 0;
  	flex: 1;
}

.event-entry-wrapper {
	display: block;
	width: 100%;
	-webkit-column-break-inside: auto;
	page-break-inside: auto;
	break-inside: auto;
}

.event-item {
	margin-bottom: 3rem;

	header {
		margin-bottom: 0.25rem;

		.entry-title {
			margin-bottom: 0.25rem;
		}

	}

	.date {
		color: theme-color("primary");
	}

}

/* Kontakt
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.lead-text {

	body.kontakt & {
		margin-bottom: 1rem;

		@include media-breakpoint-up(lg) {
			margin-top: 2rem;
			margin-bottom: 2rem;
		}

		@include media-breakpoint-up(xl) {
			margin-top: 5rem;
			margin-bottom: 3rem;
			margin-left: 5%;
		}

		@include media-breakpoint-up(xxl) {
			margin-top: 5rem;
			margin-bottom: 5rem;
		}

	}

}

.acf-map {
	margin-bottom: 2.5rem;
}
