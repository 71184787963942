/* Table of contents
––––––––––––––––––––––––––––––––––––––––––––––––––
- Font Faces
- Base Styles
- Typography
- Links
- Alerts
- Hamburger & Close Icon
- Modal
- Jumbo Image
- Panel
- Popover
- Utilities
- Forms
- Lists
- Tables
- Misc
- Clearing
*/


/* Font Faces
–––––––––––––––––––––––––––––––––––––––––––––––––– */

@import "common/font-Camila-Regular";
@import "common/font-FolioBT-Light";


/* Base Styles
–––––––––––––––––––––––––––––––––––––––––––––––––– */

html, body {
	height: 100%;
}

body {
	&.ueber-uns {
		background: theme-color("secondary");
	}

	&.kontakt {
		background: fade-out( theme-color("secondary"), 0.7);
	}

	&.no-scroll {
		overflow: hidden;
	}

}

.cover {
	background-image: url("../images/bg.jpg");
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
	position: absolute;
	left: 0;
	top: 0;
	height: 100vh;
	width: 100vw;
	z-index: 2;

	.cover-container {
		height: auto;
		text-align: center;
		width: 100%;
		z-index: 3;

		a {
			color: #fff;
		}

	}

}


/* Typography
–––––––––––––––––––––––––––––––––––––––––––––––––– */

body {
	font-size: 1.2rem;
	line-height: 1.42;

	@include media-breakpoint-up(xxl) {
		font-size: 1.4rem;
	}
	
}

.gold {
	color: theme-color("primary");
}

h1 {

	@include media-breakpoint-up(xl) {
		font-size: 3.3rem;
		line-height: 3.8rem;
	}

	@include media-breakpoint-up(xxl) {
		font-size: 4.9rem;
		line-height: 5.4rem;
	}

}

h2 {

	@include media-breakpoint-up(lg) {
		font-size: 3.75rem;
	}

}

h3 {

	@include media-breakpoint-up(lg) {
		font-size: 2.5rem;
	}

}

h4 {
	@include media-breakpoint-up(lg) {
		font-size: 2.25rem;
	}
}

/* Links
–––––––––––––––––––––––––––––––––––––––––––––––––– */

a {
	outline: 0 !important;

	main &, &.imprint-toggle {

		&:not(.agenda-weekday-collapse) {

			@include media-breakpoint-up(xl) {
				position: relative;
				display: inline-block;
				vertical-align: bottom;
				white-space: nowrap;
				overflow: hidden;

				&:before {
					position: absolute;
					bottom: 0;
					left: 0;
					z-index: -1;
					width: 100%;
					height: 2px;
					background: darken(theme-color('primary'), 15%);
					content: '';
					transition: transform 0.3s;
					transform: scaleY(1) translateX(-100%);
				}

				&:hover, &:focus {

					&:before {
						transform: scaleY(1) translateX(0);
					}

				}

			}

		}

	}

}

/* Alerts
–––––––––––––––––––––––––––––––––––––––––––––––––– */


/* Hamburger & Close Icon
–––––––––––––––––––––––––––––––––––––––––––––––––– */

// Hamburger
// 
.hamburger {
    font: inherit;
    display: inline-block;
    overflow: visible;
    margin: 0;
    cursor: pointer;
    transition-timing-function: linear;
    transition-duration: .15s;
    transition-property: opacity,filter;
    text-transform: none;
    color: inherit;
    border: 0;
    background-color: transparent;
}

.hamburger--accessible {
    display: inline-block;

    .hamburger-box {
    	display: inline-block;
    	vertical-align: middle;
	}

}

.hamburger-label {
    font-weight: 600;
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
    text-transform: uppercase;
    padding-top: 3px;
    letter-spacing: 1px;
}

// CLose Icon
// 
.close-icon {
	float: right;
	width: 40px;
	height: 40px;
	position: fixed;
	top: 20px;
	right: 15px;
	transform: rotate(0deg);
	transition: .5s ease-in-out;
	cursor: pointer;
	z-index: 1100;

	span:nth-child(1), span:nth-child(2) {
	 	display: block;
	  	position: absolute;
	  	height: 2px;
	  	width: 40px;
	  	background: #fff;
	  	opacity: 1;
	  	border-radius: 4px;
	  	left: 3px;
	  	transform: rotate(0deg);
	  	transition: .25s ease-in-out;

	  	.modal-header & {
	  		background: $gray-800;
	  	}

	}

	span:nth-child(1) {
	  	transform: rotate(45deg);
	  	top: 4px;
	  	left: 6px;
	  	transform-origin: left center;
	}

	span:nth-child(2) {
	 	transform: rotate(-45deg);
	  	top: 32px;
	  	left: 6px;
	  	transform-origin: left center;
	}

}

/* Modal
–––––––––––––––––––––––––––––––––––––––––––––––––– */

#firstPageview {

	.modal-dialog {
		transform: none;
		max-width: none;
		min-height: calc(100% - 3.5rem);
		align-items: center;
		display: flex;

		* {
			transform-origin: 50% 50%;
			perspective: 100px;
		}

	}

}

#goldModal {

	.modal-dialog {

		@include media-breakpoint-down(md) {
			max-width: none;
			margin: 0 auto;
		}

		@include media-breakpoint-only(lg) {
			max-width: 700px;
		}

	}

	.modal-content {
		border: 0;

		@include media-breakpoint-down(lg) {
			border-radius: 0;
		}

	}

	.modal-header {
		border: none;
	}

	.close-icon {
		@include media-breakpoint-up(md) {
			top: 30px;
			right: 25px;
		}
	}

	.modal-body {
		font-family: 'Camila-Regular';
		font-size: 1.5rem;
		padding: 2rem;

		@include media-breakpoint-up(md) {
			padding: 3rem;
		}

		@include media-breakpoint-up(lg) {
			padding: 3rem 6rem 6rem;
		}

		p {
			margin-bottom: 0.5rem;

			&.gold {
				text-align: center;
				font-size: 5rem;
			}

		}

	}

	h1 {
		font-size: 4rem;
		margin-bottom: 1rem;
		color: theme-color('primary');

		@include media-breakpoint-up(lg) {
			margin-bottom: 2rem;
			font-size: 5rem;
		}

	}

}

/* Jumbo Image
–––––––––––––––––––––––––––––––––––––––––––––––––– */


/* Panel
–––––––––––––––––––––––––––––––––––––––––––––––––– */


/* Popover
–––––––––––––––––––––––––––––––––––––––––––––––––– */


/* Utilities
–––––––––––––––––––––––––––––––––––––––––––––––––– */


/* Forms
–––––––––––––––––––––––––––––––––––––––––––––––––– */


/* Lists
–––––––––––––––––––––––––––––––––––––––––––––––––– */


/* Tables
–––––––––––––––––––––––––––––––––––––––––––––––––– */


/* Misc
–––––––––––––––––––––––––––––––––––––––––––––––––– */

hr {

	&.bullet {
		border: none;
		margin-bottom: 4rem;
		margin-top: 1.5rem;

		&:after {
			content: "";
			position: absolute;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			transform: translateX(-50%);
			left: 50%;
			background: $gray-800;
		}

		&.gold {

			&:after {
				background: theme-color("primary");
			}

		}

	}

}

.acf-map {
	width: 100%;
	height: 400px;

	@include media-breakpoint-up(xl) {
		height: 800px;
	}

	@include media-breakpoint-up(xxl) {
		height: 1050px;
	}

}

/* fixes potential theme css conflict */
.acf-map img {
   max-width: inherit !important;
}

.chevron {
  width: 30px;
  height: 30px;
  display: inline-block;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border-style: solid;
    border-color: theme-color("primary");
    border-width: 1px 1px 0 0;
    position: absolute;
    transform-origin: 10px 10px;
    transform: rotate(315deg); 
    transition-timing-function: cubic-bezier(.7,0,.3,1);
    transition: all .4s;
    margin-top: 15px;
  }

  a.collapsed > & {

  	&:before {
  		transform: rotate(135deg);
  		transition: all .4s;
  		margin-top: 2px;
  	}

  }

  a:hover &, a:focus & {

  	&:before {
  		border-color: darken( theme-color("primary"), 15% );
  	}

  }
 
}

/* Clearing
–––––––––––––––––––––––––––––––––––––––––––––––––– */