/**
 * @license
 * MyFonts Webfont Build ID 3587689, 2018-05-26T06:23:27-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: FolioBT-Light by Bitstream
 * URL: https://www.myfonts.com/fonts/bitstream/folio/light/
 * Copyright: Copyright 1990-2003 Bitstream Inc. All rights reserved.
 * Licensed pageviews: 10,000
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3587689
 * 
 * © 2018 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/36be69");

@font-face {
	font-family: 'FolioBT-Light';
	src: url('../fonts/36BE69_0_0.eot');
	src: url('../fonts/36BE69_0_0.eot?#iefix') format('embedded-opentype'),
	url('../fonts/36BE69_0_0.woff2') format('woff2'),
	url('../fonts/36BE69_0_0.woff') format('woff'),
	url('../fonts/36BE69_0_0.ttf') format('truetype');
}