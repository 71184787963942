// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$theme-colors: (
  "primary": #BC9453,
  "secondary": #EAD8D6
);

$body-color: #212529;


// Hamburger
$hamburgers-padding-x: 20px;
$hamburgers-padding-y: 15px;
$hamburger-types     : (collapse);
$hamburger-layer-color : #212529;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing : 9px;


// Fonts
//
$font-family-base: 'FolioBT-Light';
$headings-font-family: 'Camila-Regular';


// Links
//
$link-hover-decoration: none;


// Typography 
//
$paragraph-margin-bottom: 1.5rem;
$headings-margin-bottom: 1rem;
$font-weight-base: 300;

// Grid breakpoints
//
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1700px
);

// Grid containers
//
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1640px
);