header {
	margin-bottom: 30px;
	@include media-breakpoint-up(lg) {
		margin-bottom: 10px;
	}
}

.top-header {
	padding: 15px;
	display: none;

	@include media-breakpoint-up(lg) {
		width: 100%;
		position: absolute;
		top: 0;
		display: block;
	}

}

.backToHome {
	font-size: 2rem;
}

#nav-icon {
	position: fixed;
	z-index: 1040;
	border-radius: 0;
	top: 25px;
	right: 15px;
	padding: 0;

	@include media-breakpoint-up(sm) {
		right: 30px;
	}

}

#close-nav {

	@include media-breakpoint-up(sm) {
		top: 20px;
		right: 30px;
	}

	@include media-breakpoint-up(lg) {
		display: none;
	}

}

.navbar-brand {
	margin-left: auto;
	margin-right: auto;

	@include media-breakpoint-up(lg) {
		display: none;
	}

}

.logo-small {
	width: 110px;
	height: auto;

	@include media-breakpoint-up(xl) {
		width: 134px;
	}

}

.logo-small-invert {
	width: 50px;
	height: auto;
}

.navbar-collapse {

	@include media-breakpoint-down(md) {
		position: fixed;
		top: 0;
		max-width: 100%;
		display: flex;
		flex: 0 0 100%;
		z-index: 1040;
		height: 100%;
		transition: all 0.4s;
		background: theme-color('primary');
		left: 0;
		width: 100%;
		align-items: center !important;
		transition: all .4s;
		transition-timing-function: cubic-bezier(.7,0,.3,1);
		transform: translate3d(calc(100vw + 100%),0,0);
	}

	body.open-menu & {

		@include media-breakpoint-down(lg) {
			transform: translateZ(0);
			transition: all .8s;
		}

	}

}

#menu-primary-navigation {
	font-family: "Camila-Regular";
	font-weight: normal;
	font-style: normal;
	font-size: 1.88rem;

	@include media-breakpoint-down(md) {
		text-align: center;
		font-size: 2rem;
	}

	@include media-breakpoint-up(lg) {
		max-width: 1040px;
		padding: 0;
		margin-top: 2rem;
		margin-bottom: 3rem;
		transform: translateX(-26px);
	}

	@include media-breakpoint-up(xl) {
		font-size: 2.25rem;
	}

	.nav-item {

		&.logo {

			@include media-breakpoint-up(lg) {
				width: 120px;
				height: 120px;
			}

			@include media-breakpoint-up(xl) {
				width: 150px;
				height: 150px;
			}

		}

		.nav-link {
			color: $gray-800;

			@include media-breakpoint-down(md) {
				color: #ffffff;
			}

			&:hover, &:focus {
				@include media-breakpoint-up(xl) {
					color: $gray-900;
				}
			}
			
			&:not(.logo-link) {

				@include media-breakpoint-up(lg) {
					padding-left: 0;
					padding-right: 0;
					line-height: 1;
					padding-bottom: .25rem;
				}

				@include media-breakpoint-up(xl) {
					position: relative;
					display: inline-block;
					vertical-align: bottom;
					white-space: nowrap;
					overflow: hidden;

					&:before {
						position: absolute;
						bottom: 0;
						left: 0;
						z-index: -1;
						width: 100%;
						height: 2px;
						background: theme-color('primary');
						content: '';
						transition: transform 0.3s;
						transform: scaleY(1) translateX(-100%);
					}

					&:hover, &:focus {

						&:before {
							transform: scaleY(1) translateX(0);
						}

					}

				}

			}

		}

		&.current_page_item {

			.nav-link {

				@include media-breakpoint-up(xl) {

					&:before {
						transform: scaleY(1) translateX(0);
					}

				}

			}	

		}

	}

}