footer {
	margin-bottom: 2rem;
	font-size: 1.25rem;

	.logo-small {
		width: 62px;
		height: auto;
		margin-bottom: 3.5rem;

		@include media-breakpoint-up(xl) {
			margin-top: 8rem;
			margin-bottom: 9rem;
		}
		
	}

	.social-list {
		font-size: 2rem;
	}

}

#imprint {
	padding-top: 4rem;
	padding-bottom: 2rem;
	font-size: 1rem;
	display: none;

	h3 {
		font-size: 1.5rem;
		margin-bottom: 0.3rem;
	}

}